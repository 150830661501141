.floating-card-container {
  position: relative;
  width: 500px;
  height: calc(100vh - 215px);
  margin: 0 auto;
  transform: translate(187px, 71px);
}

.floating-card-container-sm {
  position: relative;
  width: 100%;
  height: 250px;
  margin: 0 auto;
  transform: translate(223px, 6px);
}

.floating-card-container-sm.single {
  transform: none;
  width: auto;
  height: auto;
}

.floating-card {
  position: absolute;
  width: 250px;
  height: auto;
  /* background-color: #fff; */
  border-radius: 17px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.floating-card-container-sm.single .floating-card-sm {
  position: relative !important;
  margin: 0 auto;
  display: block;
  width: 180px;

}

.floating-card-sm {
  position: absolute;
  width: 120px;
  height: auto;
  /* background-color: #fff; */
  border-radius: 17px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.floating-card-single {
  /* position: absolute; */
  width: 180px;
  height: auto;
  border-radius: 17px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0 auto;
}

.floating-card img,
.floating-card-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Card angles and positioning for flush/fan effect */
.floating-card-1 {
  transform: rotate(-10deg) translate(-457px, 50px); /* Left and rotated */
  z-index: 1; /* Ensure it is underneath others */
}
.floating-card-1-sm {
  transform: rotate(-10deg) translate(-229px, 18px);
  z-index: 1; /* Ensure it is underneath others */
}

.floating-card-2 {
  transform: rotate(-8deg) translate(-313px, -3px);
  z-index: 2;
}
.floating-card-2-sm {
  transform: rotate(-5deg) translate(-182px, 5px);
  z-index: 2;
}

.floating-card-3 {
  transform: rotate(-5deg) translate(-164px, 36px);
  z-index: 3;
}
.floating-card-3-sm {
  transform: rotate(-5deg) translate(-90px, 14px);
  z-index: 3;
}

.floating-card-4 {
  transform: rotate(0deg) translateX(5px); /* Neutral position */
  z-index: 4;
}
.floating-card-4-sm {
  transform: rotate(0deg) translateX(-17px);
  z-index: 4;
}

.floating-card-5 {
  transform: rotate(11deg) translate(187px, 14px);
  z-index: 7;
}
.floating-card-5-sm {
  transform: rotate(11deg) translate(64px, 14px);
  z-index: 5;
}

.floating-card-6 {
  transform: rotate(16deg) translate(354px, 37px); /* Right and rotated */
  z-index: 6;
}
.floating-card-6-sm {
  transform: rotate(10deg) translate(139px, 35px);
  z-index: 6;
}

/* Glow animation */
@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px rgba(119, 195, 93, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(46, 184, 52, 0.517), 0 0 30px rgba(176, 103, 168, 0.6);
  }
}

/* Apply the glow animation to each card in sequence */
.floating-card-1,
.floating-card-single-sm,
.floating-card-1-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 0s;
}

.floating-card-2,
.floating-card-2-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 0.5s;
}

.floating-card-3,
.floating-card-3-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 1s;
}

.floating-card-4,
.floating-card-4-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 1.5s;
}

.floating-card-5,
.floating-card-5-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 2s;
}

.floating-card-6,
.floating-card-6-sm {
  animation: glow 3s ease-in-out infinite;
  animation-delay: 2.5s;
}