.phone-container2 {
    position: relative;
    width: 1400px;
    height: 1200px;
    left: -1rem;
    top: -9rem;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    /* align-items: flex-end;  */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    /* border: 1px solid #ddd; */
}

.overlay-text2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    top: 62%;
    color: white;
    text-align: center;
    padding: 0.8rem 0;
}

.wizard-card2 {
    position: absolute;
    bottom: 37rem;
    left: 42rem;
    rotate: 12deg;
    width: 20%;
    height: auto;
    z-index: -3; 
}

.nessarose-card2 {
    position: absolute;
    bottom: 37rem;
    left: 27rem;
    rotate: -12deg;
    width: 20%;
    height: auto;
    z-index: -3; 
}

.elphaba-card2 {
    position: absolute;
    width: 20%;
    height: auto;
    rotate: -16.126deg;
    left: 24rem;
    bottom: 27rem;
    z-index: -1;
}

.madame-card2 {
    position: absolute;
    width: 20%;
    height: auto;
    rotate: 14.887deg;
    bottom: 32.5rem;
    right: 25.9rem;
    z-index: -2; 
}

.fiyero-card2 {
    position: absolute;
    width: 20%;
    height: auto;
    rotate: 16.126deg;
    left: 50rem;
    bottom: 27rem;
    z-index: -1; 
}

.glinda-card2 {
    position: absolute;
    width: 20%;
    height: auto;
    rotate: -14.887deg;
    bottom: 32.5rem;
    left: 25.9rem;
    z-index: -2; 
}
