/* Card Animtions */
.pack-container-sm {
  width: 240px; /* Larger than the cards */
  height: 360px; /* Larger than the cards */
  position: relative;
  margin: 240px auto 41px auto; /* Adding margin to simulate scrolling */
  perspective: 1000px; /* Adds depth */
  overflow: visible; /* Allow cards to move outside of the container */
}

.pack-container {
  width: 464px; /* Larger than the cards */
  height: 600px; /* Larger than the cards */
  position: relative;
  margin: 350px auto 41px auto; /* Adding margin to simulate scrolling */
  perspective: 1000px; /* Adds depth */
  overflow: visible; /* Allow cards to move outside of the container */
}

/* Use an img tag for the card pack */
.card-pack-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  border-radius: 15px; /* Optional: You can remove if your image has built-in borders */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.card {
  width: 328px;
  height: 433px;
  /* background-color: #fff; */
  border-radius: 10px;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 0;
  transition: all 0.5s ease;
}

.card-sm {
  width: 180px;
  height: 245px;
  top: 42%;
}


.reveal .card:nth-child(1) {
  animation: revealCard1 1.5s ease-out forwards 0.1s;
}

.reveal .card:nth-child(2) {
  animation: revealCard2 1.5s ease-out forwards 0.2s;
}

.reveal .card:nth-child(3) {
  animation: revealCard3 1.5s ease-out forwards 0.3s;
}

.reveal .card:nth-child(4) {
  animation: revealCard4 1.5s ease-out forwards 0.4s;
}

.reveal .card:nth-child(5) {
  animation: revealCard5 1.5s ease-out forwards 0.5s;
}

.reveal .card:nth-child(6) {
  animation: revealCard6 1.5s ease-out forwards 0.6s;
}

/* Cards fan out horizontally left and right with more spread */
@keyframes revealCard1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-65%, -120%) rotate(-6deg); /* More left fan */
  }
}

@keyframes revealCard2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-66%, -115%) rotate(-5deg); /* A bit more spread */
  }
}

@keyframes revealCard3 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-55%, -110%) rotate(-2deg); /* Subtle fan out */
  }
}

@keyframes revealCard4 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-45%, -110%) rotate(2deg); /* Slight right fan */
  }
}

@keyframes revealCard5 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-40%, -120%) rotate(4deg); /* Slight right fan */
  }
}

@keyframes revealCard6 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-30%, -130%) rotate(7deg); /* More right fan */
  }
}
