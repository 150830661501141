.redeemed-header-image {
    position: absolute;
    left: 10%;
    top: 4%;
    height: 6rem;
    cursor: pointer;
  }
  
.redeemed-logout-btn {
    /* position: absolute; */
    /* right: 10px; */
    /* top: 4%; */
    /* width: 12.1rem;
    height: 3.9rem;
    padding: 10px 20px; */
    border: 1px solid #14a905;
    background-color: transparent;
    color: #fff;
    border-radius: 77px;
}

  
  

/* .universal-logo {
    position: absolute;
    bottom: 3.5%;
    left: 50%;
    transform: translate(-50%);
    width: 121px; 
    height: auto;
    z-index: -100;
} */

@keyframes glowRotate {
  0% {
    box-shadow: 0 0 10px pink, 0 0 20px green;
  }
  25% {
    /* box-shadow: 20px -20px 30px green, -20px 20px 40px pink; */
  }
  50% {
    box-shadow: 0 0 40px green, 0 0 50px pink;
  }
  75% {
    /* box-shadow: -20px 20px 30px pink, 20px -20px 40px green; */
  }
  100% {
    box-shadow: 0 0 10px pink, 0 0 20px green;
  }
}

.glow-box {
  /* width: 150px; */
  /* height: 150px; */
  background-color: transparent;
  /* border-radius: 50%; */
  position: relative;
  animation: glowRotate 3s infinite linear;
}

.glow-box img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  position: absolute;
  top: 0;
  left: 0;
}

.card-container {
  /* width: 300px;
  height: 400px; */
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7), 0 0 20px rgba(255, 105, 180, 0.4), 0 0 40px rgba(0, 255, 127, 0.3); /* Initial glow */
  animation: glowRotate 5s infinite alternate ease-in-out; /* Continuous glow animation */
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transform-origin: center;
}

.card-container:hover {
  transform: scale(1.1); /* Slightly enlarge the card */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7), 0 0 40px rgba(255, 105, 180, 0.6), 0 0 80px rgba(0, 255, 127, 0.5); /* Enhance glow on hover */
}

.card-container::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -150%;
  width: 200%;
  height: 300%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 100%);
  transition: all 0.5s ease;
  transform: rotate(25deg);
  opacity: 0;
}

.card-container:hover::before {
  opacity: 1;
  animation: shiny 1s forwards;
}

@keyframes shiny {
  0% {
    top: -100%;
    left: -150%;
  }
  100% {
    top: 100%;
    left: 150%;
  }
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7), 0 0 20px rgba(255, 105, 180, 0.4), 0 0 40px rgba(0, 255, 127, 0.3);
  }
  100% {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7), 0 0 30px rgba(255, 105, 180, 0.6), 0 0 60px rgba(0, 255, 127, 0.4);
  }
}
