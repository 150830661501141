body {
  background: linear-gradient(180deg, #162F13 0.51%, #000 63.39%);
  margin: 0;
  padding: 0;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 1px solid #ddd; */
  height: 100%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, proxima-nova, sans-serif !important;
}

/* body {
    height: 0vh;
  } */


.claim-header-image {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 100px;
}

.claim-logout-btn {
  position: absolute;
  right: 10%;
  top: 4%;
  width: 12.1rem;
  height: 3.9rem;
  padding: 10px 20px;
  border: 1px solid #14a905;
  background-color: transparent;
  color: #fff;
  border-radius: 77px;
}

/* Background image */
.bg-image {
  width: 100%;
  max-width: 1024px;
  display: block;
  margin: 0 auto;
  margin-top: 142px;
  z-index: -1;
  /* Behind the modal */
}

.modalClaimed {
  position: absolute;
  background-color: #000;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  z-index: 10;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.modalClaimed p {
  text-align: left;
  /* font-family: 'Century Gothic Regular'; */
  /* margin-bottom: 20px; */
}

.modal {
  background-color: #2b2b2b;
  border-radius: 24px;
  padding: 2.3rem;
  width: 356px;
  height: auto;
  text-align: center;
  margin: 0 auto;
  top: calc(50% - 188px);
  left: calc(50% - 219px);
  z-index: 10;
  position: fixed;
}

.modal p {
  text-align: left;
  /* font-family: 'Century Gothic Regular'; */
  /* margin-bottom: 20px; */
}

.modal-sm {
  background-color: #191919;
  border-radius: 24px;
  padding: 36px 0;
  width: 100%;
  height: auto;
  text-align: center;
  margin: 0 auto;
  margin-top: 140px;
  top: calc(50% - 188px);
  left: 0;
  position: relative;
}

input[type='text'] {
  border: 0px solid #fff;
  border-radius: 9px;
  height: 3.9rem;
  padding: 0rem 0.3rem;
  font-size: 20px;
  background-color: #454545;
  /* font-family: 'Century Gothic Regular'; */
}

input[type='text']:focus {
  outline: none;
  /* Removes default focus outline */
  border-color: #14a905;
  /* Green border on focus */
  box-shadow: 0 0 5px #14a905;
  /* Green glow on focus */
}

button {
  background-color: #14a905;
  color: white;
  border: none;
  padding: 10px 15px;
  /* width: 15rem; */
  max-width: 200px;
  height: 2.9rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  /* margin-top: 1.5rem; */
  margin-top: 20px;
  border-radius: 77px;
  font-weight: 600;
}

.button-glow {
  border-color: #14a905;
  box-shadow: 0 0 90px #14a905;
  transition: box-shadow .5s ease;
}

button-glow button:disabled,
button[disabled] {
  opacity: 0.5 !important;
}

.link-btn {
  text-align: center;
  color: #14a905;
  text-decoration: none;
  display: block;
  /* width: 100%; */
  max-width: 200px;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  font-size: 17px;
  border: 2px solid #14a905;
  padding: 10px 26px;
  border-radius: 100px;
  margin: 0 auto;
  ;
}

/* button:hover {
    background-color: #218838;
  } */


.universal-logo {
  position: absolute;
  bottom: 3.5%;
  left: 50%;
  transform: translate(-50%);
  width: 121px;
  height: auto;
}